import pdf from "../privacy/politicas-de-privacidade.pdf";

function Footer() {

  return (
    <>
      <div className="d-flex justify-content-center align-items-center text-center flex-direction-column" style={{ backgroundColor: '#ff6900', width: '100%', padding: '0.5rem 0rem 0rem 0', zIndex: '10' }}>
        <a href={pdf} target="_blank" rel="noopener noreferrer" className="privacy-link" style={{ color: '#f5f5f5', fontWeight: 600, fontSize: '1rem', marginBottom: '0.2rem' }}>
          Políticas de Privacidade
        </a>
      </div>
      <div className="d-flex justify-content-center align-items-center text-center flex-direction-column" style={{ backgroundColor: '#ff6900', width: '100%', padding: '2rem 0rem 1rem 0', zIndex: '10' }}>
        <p style={{ color: '#f5f5f5', fontWeight: 600, fontSize: '0.8rem' }}>&copy; {new Date().getFullYear()} - Gol Plus Proteção Veicular | Desenvolvido por Agência Upstairs.</p>
      </div>
    </>
  );
}

export default Footer;