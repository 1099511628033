import logoGolWhite from '../img/logo-golplus-white.svg'

function NavBar() {

  return (
    <div className="navbar-floating">
      <div className="navbar-container">
        <img src={logoGolWhite} width={'170px'} alt='logo gol plus abc' />
        <div className="navbar-buttons-container">
          {/* <a href='tel:1123798040'>
            <button className="phone">
              <span>Simule Agora!</span>
              (11) 2379-8040
            </button>
          </a> */}
          <a href='https://wa.me/1123798040?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Gol%20Plus%20ABC'>
            <button className="whatsapp">
              <span>Simule no WhatsApp!</span>
              (11) 2379-8040
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default NavBar;