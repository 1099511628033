import { useEffect, useState } from 'react';
import { ChatBox } from '../component/chat';
import HeaderSlider from '../component/header-slider';
import Footer from '../component/footer'
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, } from 'mdb-react-ui-kit';
import '../index.css';
import { GiBandit } from "react-icons/gi";
import { FaCarCrash } from "react-icons/fa";
import { GiBrazil } from "react-icons/gi";
import { FaUserShield } from "react-icons/fa";
import { GiTowTruck } from "react-icons/gi";
import { GiThreeKeys } from "react-icons/gi";
import { GiCarWheel } from "react-icons/gi";
import { FaTaxi } from "react-icons/fa";
import { FaHotel } from "react-icons/fa";
import { FiCheckCircle } from "react-icons/fi";
import { BiSolidCarGarage, BiMessageSquareDetail } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa";
import { useResponsiveMode } from '../controller/utils';
import NavBar from '../component/navbar';
import premioReclameAqui from '../img/selo-reclame-aqui.webp';
import InitialBkg from '../img/initial-bkg.jpg';
import BkgBike from '../img/bkg-hero1.jpg';
import BkgCar from '../img/bkg-hero2.webp';
import BkgTruck from '../img/bkg-hero3.jpg';


function Index() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const goToElement = (elementId) => {
        const element = document.getElementById(elementId);
        element.scrollIntoView();
    };

    const slides = [
        {
            bg: InitialBkg, title: `Proteja Seu 
Veículo com a 
Nossa Assistência 
24 Horas!`, cta_text: '', cta_url: '', cta_url_class: ''
        }, {
            bg: BkgBike, title: `Faça uma simulação
e descubra o quão
acessível é 
proteger seu 
veículo.`, cta_text: '', cta_url: '', cta_url_class: ''
        }, {
            bg: BkgCar, title: `Descubra o plano 
ideal para você:
Faça uma 
cotação online
agora mesmo!`, cta_text: '', cta_url: '', cta_url_class: ''
        }, {
            bg: BkgTruck, title: `Esteja protegido:
Estamos disponíveis 
24h Para auxiliá-lo.`, cta_text: '', cta_url: '', cta_url_class: ''
        },
    ];

    const isMobile = useResponsiveMode();
    const [view, setView] = useState('home');
    const [activeIcon, setActiveIcon] = useState(null);

    const handleIconClick = (icon) => {
        setActiveIcon(icon === activeIcon ? null : icon);
    };

    return (
        <>
            {view === 'home' ?
                <div>
                    <NavBar />
                    <MDBContainer id='home' fluid className="headerContainer">
                        <HeaderSlider slides={slides} />
                        {/* {!isMobile && (<div id='ia-assist' className='chatbox-container'> <ChatBox setView={setView} /> </div>)} */}
                    </MDBContainer>
                    <MDBContainer fluid className='bg-default'>
                        <MDBRow className='justify-content-center align-items-center'>
                            <MDBCol md={8} className='container-about-section'>
                                <p>Na <span>Gol Plus Regional ABC</span>, sua proteção veicular é nossa prioridade. Com cobertura completa e suporte dedicado, garantimos que seu veículo esteja sempre protegido. Desfrute da tranquilidade de uma proteção confiável e eficiente. Escolha a <span>Gol Plus Regional ABC, sua companheira em todas as trilhas e destinos.</span></p>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    <MDBContainer>
                        <div className='circles-holder'>
                            <MDBRow style={{ textAlign: 'center' }} className='justify-content-start align-items-start'>
                                <MDBCol>
                                    <div className={`icon-container ${activeIcon === 'reboque' ? 'expanded' : ''}`} onClick={() => handleIconClick('reboque')}>
                                        <span className='icon-span mt-3 mb-3'>
                                            <GiTowTruck size={'3rem'} color='#f5f5f5' />
                                        </span>
                                        <h4>Reboque</h4>
                                        <div className='content'>
                                            <p>Serviço de reboque rápido e confiável com Gol Plus ABC. Assistência imediata para qualquer emergência na estrada. Nunca fique parado. Junte-se a nossa proteção veicular hoje!</p>
                                            <button onClick={() => window.open('https://wa.me/1123798040?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Gol%20Plus%20ABC', '_blank')}>
                                                Faça uma cotação
                                            </button>
                                        </div>
                                    </div>
                                </MDBCol>
                                <MDBCol>
                                    <div className={`icon-container ${activeIcon === 'chaveiro' ? 'expanded' : ''}`} onClick={() => handleIconClick('chaveiro')}>
                                        <span className='icon-span mt-3 mb-3'>
                                            <GiThreeKeys size={'3rem'} color='#f5f5f5' />
                                        </span>
                                        <h4>Chaveiro</h4>
                                        <div className='content'>
                                            <p>Assistência de chaveiro 24 horas com Gol Plus ABC. Solucionamos problemas com chaves e fechaduras do seu veículo a qualquer hora. Tranquilidade sempre. Junte-se a nossa proteção veicular hoje!                                            </p>
                                            <button onClick={() => window.open('https://wa.me/1123798040?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Gol%20Plus%20ABC', '_blank')}>
                                                Faça uma cotação
                                            </button>
                                        </div>
                                    </div>
                                </MDBCol>
                                <MDBCol>
                                    <div className={`icon-container ${activeIcon === 'taxi' ? 'expanded' : ''}`} onClick={() => handleIconClick('taxi')}>
                                        <span className='icon-span mt-3 mb-3'>
                                            <FaTaxi size={'3rem'} color='#f5f5f5' />
                                        </span>
                                        <h4>Táxi</h4>
                                        <div className='content'>
                                            <p>Serviço de táxi emergencial com Gol Plus ABC. Mobilidade garantida quando seu veículo precisar de reparos. Conforto e conveniência. Junte-se a nossa proteção veicular hoje!                                            </p>
                                            <button onClick={() => window.open('https://wa.me/1123798040?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Gol%20Plus%20ABC', '_blank')}>
                                                Faça uma cotação
                                            </button>
                                        </div>
                                    </div>
                                </MDBCol>
                                <MDBCol>
                                    <div className={`icon-container ${activeIcon === 'hospedagem' ? 'expanded' : ''}`} onClick={() => handleIconClick('hospedagem')}>
                                        <span className='icon-span mt-3 mb-3'>
                                            <FaHotel size={'3rem'} color='#f5f5f5' />
                                        </span>
                                        <h4>Hospedagem</h4>
                                        <div className='content'>
                                            <p>Hospedagem em caso de imprevistos com Gol Plus ABC. Se a viagem precisar de uma pausa, conte conosco para conforto e segurança. Junte-se a nossa proteção veicular hoje!                                            </p>
                                            <button onClick={() => window.open('https://wa.me/1123798040?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Gol%20Plus%20ABC', '_blank')}>
                                                Faça uma cotação
                                            </button>
                                        </div>
                                    </div>
                                </MDBCol>
                                <MDBCol>
                                    <div className={`icon-container ${activeIcon === 'guarda-veiculo' ? 'expanded' : ''}`} onClick={() => handleIconClick('guarda-veiculo')}>
                                        <span className='icon-span mt-3 mb-3'>
                                            <BiSolidCarGarage size={'3rem'} color='#f5f5f5' />
                                        </span>
                                        <h4>Guarda do Veículo</h4>
                                        <div className='content'>
                                            <p>Guarda de veículos segura com Gol Plus ABC. Proteção total enquanto seu carro está em manutenção ou reparo. Segurança garantida. Junte-se a nossa proteção veicular hoje!                                            </p>
                                            <button onClick={() => window.open('https://wa.me/1123798040?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Gol%20Plus%20ABC', '_blank')}>
                                                Faça uma cotação
                                            </button>
                                        </div>
                                    </div>
                                </MDBCol>
                                <MDBCol>
                                    <div className={`icon-container ${activeIcon === 'troca-pneus' ? 'expanded' : ''}`} onClick={() => handleIconClick('troca-pneus')}>
                                        <span className='icon-span mt-3 mb-3'>
                                            <GiCarWheel size={'3rem'} color='#f5f5f5' />
                                        </span>
                                        <h4>Troca de Pneus</h4>
                                        <div className='content'>
                                            <p>Troca de pneus eficiente e rápida com Gol Plus ABC. Serviço confiável para manter você rodando sem problemas. Rodando sempre. Junte-se a nossa proteção veicular hoje!                                            </p>
                                            <button onClick={() => window.open('https://wa.me/1123798040?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Gol%20Plus%20ABC', '_blank')}>
                                                Faça uma cotação
                                            </button>
                                        </div>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </MDBContainer>
                    <MDBContainer id='vantagens' fluid className='vantagens-container'>
                        <MDBRow className='justify-content-center align-items-center'>
                            <MDBCol md={6}>
                                <h1 className='vantagens-title'>
                                    <span>Vantagens</span> da <span>Gol Plus</span> <br /> <span>Proteção</span> Veicular
                                </h1>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className='cardsHolder justify-content-center align-items-center'>
                            <MDBCol md='6'>
                                <MDBCard className='cardsProtec'>
                                    <MDBCardBody>
                                        <div className='icon-services-container'><GiBandit style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                        <h3>
                                            Furto / Roubo
                                        </h3>
                                        <p>
                                            Em caso de furto ou roubo do seu veículo, garantimos o ressarcimento de até 100% do valor da Tabela FIPE.
                                        </p>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md='6'>
                                <MDBCard className='cardsProtec'>
                                    <MDBCardBody>
                                        <div className='icon-services-container'><FaCarCrash style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                        <h3>
                                            Colisão
                                        </h3>
                                        <p>
                                            Em situações de acidente, cuidamos do conserto do seu veículo para que você possa voltar à estrada com tranquilidade.
                                        </p>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md='6'>
                                <MDBCard className='cardsProtec'>
                                    <MDBCardBody>
                                        <div className='icon-services-container'><GiBrazil style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                        <h3>
                                            Cobertura Nacional
                                        </h3>
                                        <p>
                                            Com cobertura em todo o Brasil, esteja onde estiver, conte conosco para fornecer assistência quando precisar, em qualquer lugar do país.
                                        </p>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md='6'>
                                <MDBCard className='cardsProtec'>
                                    <MDBCardBody>
                                        <div className='icon-services-container'><FaUserShield style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                        <h3>
                                            Proteção para terceiros
                                        </h3>
                                        <p>
                                            Em caso de acidente envolvendo seu veículo e terceiros, nós nos responsabilizamos pelos reparos necessários, proporcionando segurança para você e para os outros envolvidos.
                                        </p>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md='6'>
                                <MDBCard className='cardsProtec'>
                                    <MDBCardBody>
                                        <div className='icon-services-container'><FiCheckCircle style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                        <h3>
                                            Sem burocracia
                                        </h3>
                                        <p>
                                            Oferecemos um serviço ágil e eficiente, sem burocracia, para que você possa contar conosco nos momentos mais críticos, sem complicações.
                                        </p>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md='7' className='justify-content-center align-items-center py-4 d-flex'>
                                <button id='btn-cta-cotacao_vantagens' onClick={() => goToElement('contato')} className='contactBtn vantagens'>Solicite sua cotação</button>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    <MDBContainer>
                        <MDBRow className='premio-reclame-aqui justify-content-center align-items-center'>
                            <MDBCol md={6}>
                                <img src={premioReclameAqui} alt='Selo Reclame Aqui' id='img_reclame_aqui' />
                            </MDBCol>
                            <MDBCol md={5}>
                                <h4>Qualidade e Confiança</h4>
                                <p>Celebramos na Gol Plus a conquista do Prêmio Reclame Aqui, um símbolo de confiança e qualidade em
                                    proteção veicular. Seu voto reforça nossa paixão por servir com excelência. Juntos, seguimos na
                                    liderança do mercado!</p>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    <MDBContainer fluid className='contato-container' id='contato'>
                        <MDBRow className='justify-content-center align-items-center'>
                            <MDBCol md={4} className='text-center contact-col'>
                                <div className='contact-container'>
                                    <span className='contact-icon'><BiMessageSquareDetail /></span>
                                    <h4>Entre em contato agora mesmo!</h4>
                                    {/* <a href='tel:1123798040'>
                                        <button className="contactBtn phone">
                                            <span>Simule Agora!</span>
                                            (11) 2379-8040
                                        </button>
                                    </a> */}
                                    <a href='https://wa.me/1123798040?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Gol%20Plus%20ABC'>
                                        <button className="contactBtn whatsapp">
                                            <span>Simule no WhatsApp!</span>
                                            (11) 2379-8040
                                        </button>
                                    </a>
                                    {/* <button id='btn-cta-cotacao' onClick={() => goToElement('contato')} className='contactBtn'>Clique aqui e faça sua cotação!</button> */}
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>

                    <Footer />
                    <a id='whatsappFloat' href="https://wa.me/1123798040?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Gol%20Plus%20ABC" rel="noreferrer" class="whatsapp-float hover-shadow" target="_blank">
                        <FaWhatsapp />
                    </a>
                    {/* {isMobile && (
                        <span id='upflow-float' onClick={() => setView('chat')} rel="noreferrer" class="upflow-float hover-shadow" target="_blank">
                            IA
                        </span>)
                    } */}
                </div>
                :
                <div>
                    <ChatBox setView={setView} />
                </div>
            }
        </>
    )
}

export default Index;